<script setup>
import CheckoutAsGuest from '@/components/right-panel-for-auth/CheckoutAsGuest.vue'
import LinkDollar from '@/components/right-panel-for-auth/LinkKDollar.vue'
import Login from '@/components/right-panel-for-auth/Login.vue'
import Register from '@/components/right-panel-for-auth/Register.vue'

const componentShow = ref('login')
const props = defineProps({
  panelClass: {
    type: String,
  },
})

const isKDollarEnabled = useRuntimeConfig().isKDollarEnabled

const onLinkClick = (link) => {
  if (link === 'login') {
    componentShow.value = 'login'
  } else if (link === 'register') {
    componentShow.value = 'register'
  } else if (link === 'guest') {
    componentShow.value = 'guest'
  } else if (link === 'kDollar' && isKDollarEnabled) {
    componentShow.value = 'kDollar'
  }
}
</script>

<style lang="scss">
@import 'assets/css/components/nu-right-panel-for-auth.css';
</style>

<template>
  <div>
    <div
      :class="panelClass"
      id="right-panel-for-auth"
      class="nu-right-panel-wrapper"
    >
      <div
        class="nu-right-panel-close-button"
        @click="$emit('closeAuthPanel')"
      >
        <font-awesome-icon :icon="['fas', 'xmark']" />
      </div>

      <Login
        v-if="componentShow === 'login'"
        @linkClick="onLinkClick"
        @loggedIn="$emit('closeAuthPanel')"
      />
      <CheckoutAsGuest
        v-if="componentShow === 'guest'"
        @linkClick="onLinkClick"
        @loggedIn="$emit('closeAuthPanel')"
      />
      <Register
        v-if="componentShow === 'register'"
        @linkClick="onLinkClick"
        @loggedIn="$emit('closeAuthPanel')"
      />
      <LinkDollar v-if="componentShow === 'kDollar'" />
    </div>
    <div
      class="bg-overlay"
      @click="$emit('closeAuthPanel')"
    ></div>
  </div>
</template>
