<script setup>
import {CHINA} from '@/utils/getChinaCountryCodes'

const {t} = useI18n()
const preferencesDataStore = usePreferencesDataStore()
const accountStore = useAccountStore()
const {error: errorToast} = useToast()
const auth = useAuthStore()
const emit = defineEmits(['loggedIn'])
const firstName = ref('')
const lastName = ref('')
const emailAddress = ref('')
const newsAndOffers = ref(false)
const termsAndConditions = ref(false)
const phoneNumber = ref('')
const phoneCode = ref({
  id: '',
  text: '',
  flag: '',
})
const chinaCityChoices = ref(null)
const chineseCitiesList = ref([])

const state = reactive({
  firstNameErrorMessage: '',
  lastNameErrorMessage: '',
  emailErrorMessage: '',
  termsAndConditionsErrorMessage: '',
  phoneNumberErrorMessage: '',
})

const chineseCities = await preferencesDataStore.getCitiesOfCountry(CHINA.iso_code)
chineseCitiesList.value = chineseCities?.map((city) => ({value: city.id, label: city.name})) || []

function resetFields() {
  firstName.value = ''
  lastName.value = ''
  emailAddress.value = ''
  newsAndOffers.value = ''
  termsAndConditions.value = ''
  phoneCode.value.text = ''
  phoneCode.value.id = ''
  phoneCode.value.flag = ''
  phoneNumber.value = ''
}

const getTermsPolicyText = computed(() => {
  return t('comp_right_panel_for_auth_checkout_as_guest.terms_and_policy_agreement', {
    termsLink: `<a class="terms-and-condition-link" href="#">${t(
      'comp_right_panel_for_auth_checkout_as_guest.terms_and_conditions_text',
    )}</a>`,
    privacyLink: `<a class="terms-and-condition-link" href="#">${t(
      'comp_right_panel_for_auth_checkout_as_guest.privacy_policy_text',
    )}</a>`,
    cookiesLink: `<a class="terms-and-condition-link" href="#">${t(
      'comp_right_panel_for_auth_checkout_as_guest.cookies_policy_text',
    )}</a>`,
  })
})

const handleGuestLogin = async () => {
  if (!firstName.value || firstName.value === '') {
    state.firstNameErrorMessage = t('comp_right_panel_for_auth_checkout_as_guest.required')
    return
  }
  state.firstNameErrorMessage = ''
  if (!lastName.value || lastName.value === '') {
    state.lastNameErrorMessage = t('comp_right_panel_for_auth_checkout_as_guest.required')
    return
  }
  state.lastNameErrorMessage = ''
  if (!emailAddress.value || emailAddress.value === '') {
    state.emailErrorMessage = t('comp_right_panel_for_auth_checkout_as_guest.required')
    return
  }
  state.emailErrorMessage = ''

  if (!phoneNumber.value || phoneNumber.value === '') {
    state.phoneNumberErrorMessage = t('comp_right_panel_for_auth_checkout_as_guest.required')
    return
  }
  state.phoneNumberErrorMessage = ''

  if (!termsAndConditions.value) {
    state.termsAndConditionsErrorMessage = t('comp_right_panel_for_auth_checkout_as_guest.terms_required')
    return
  }
  state.termsAndConditionsErrorMessage = ''
  const payload = {
    email: emailAddress.value,
    first_name: firstName.value,
    last_name: lastName.value,
    phone_code: phoneCode.value.text,
    phone_number: phoneNumber.value,
    will_subscribe: newsAndOffers.value,
    confirm_policy: termsAndConditions.value,
    country_code_alpha2: phoneCode.value.flag,
    city: chinaCityChoices.value,
  }

  const {data, error} = await accountStore.checkoutAsGuestUser(payload)
  const responseData = data.value?.data

  if (error.value && error.value?.data?.data?.length > 0) {
    const errors = error.value?.data?.data
    errorToast(errors?.map((error) => error.message)?.join('\n'))
    return
  }

  if (responseData?.access_token) {
    const {access_token, refresh_token} = responseData
    emit('loggedIn')
    auth.setToken(access_token)
    auth.setRefreshToken(refresh_token)
    auth.fetchUser()
    resetFields()
  }
}
</script>

<style lang="scss">
@import 'assets/css/components/nu-right-panel-for-auth.css';
</style>

<template>
  <div class="nu-right-panel-body">
    <div class="nu-right-panel-header">
      <h2>{{ $t('comp_right_panel_for_auth_checkout_as_guest.checkout_as_guest') }}</h2>
      <div class="nu-right-panel-links">
        <span @click="$emit('linkClick', 'login')">
          {{ $t('comp_right_panel_for_auth_checkout_as_guest.login_into_your_account') }}
        </span>
        {{ $t('comp_right_panel_for_auth_checkout_as_guest.or') }}
        <span @click="$emit('linkClick', 'register')">
          {{ $t('comp_right_panel_for_auth_checkout_as_guest.create_an_account') }}
        </span>
      </div>
    </div>
    <div class="nu-right-panel-content">
      <NuInput
        :errorMessage="state.firstNameErrorMessage"
        :label="$t('comp_right_panel_for_auth_checkout_as_guest.full_name')"
        id="firstName"
        class="custom-input"
        type="text"
        v-model="firstName"
      />
      <NuInput
        :errorMessage="state.lastNameErrorMessage"
        :label="$t('comp_right_panel_for_auth_checkout_as_guest.last_name')"
        id="lastName"
        class="custom-input"
        type="text"
        v-model="lastName"
      />
      <NuInput
        :errorMessage="state.emailErrorMessage"
        :label="$t('comp_right_panel_for_auth_checkout_as_guest.email_address')"
        id="emailAddress"
        class="custom-input"
        type="text"
        v-model="emailAddress"
      />
      <NuPhoneNumber
        :errorMessage="state.phoneNumberErrorMessage"
        :label="$t('comp_right_panel_for_auth_checkout_as_guest.phone_number')"
        v-model:phoneCode="phoneCode"
        v-model:phoneNumber="phoneNumber"
        @onPhoneCodeSelect="($emit) => (phoneCode = $emit)"
      />
      <div
        class="col-md-8"
        v-if="phoneCode.text === CHINA.phone_code"
      >
        <NuDropDown
          :label="$t('page_my_preference.city')"
          :modelValue="chinaCityChoices"
          :options="chineseCitiesList"
          id="china-city-choices"
          class="custom-input"
          optionKey="value"
          optionValue="label"
          @update:modelValue="chinaCityChoices = $event"
        />
      </div>
      <NuCheckbox
        :label="$t('comp_right_panel_for_auth_checkout_as_guest.news_and_offers_text')"
        id="newsAndOffers"
        class="custom-checkbox"
        type="checkbox"
        v-model="newsAndOffers"
      />
      <NuCheckbox
        :errorMessage="state.termsAndConditionsErrorMessage"
        id="termsAndConditions"
        class="custom-checkbox"
        type="checkbox"
        v-model="termsAndConditions"
      >
        <template #label>
          <NuHtmlLoader :html="getTermsPolicyText"></NuHtmlLoader>
        </template>
      </NuCheckbox>
      <div class="authentication-actions">
        <NuButton
          :display="'primary'"
          class="checkout-btn"
          @click="handleGuestLogin"
        >
          {{ $t('comp_right_panel_for_auth_checkout_as_guest.checkout_as_guest') }}
        </NuButton>
      </div>
    </div>
  </div>
</template>
