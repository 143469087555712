<script setup>
const {t} = useI18n()
const localePath = useLocalePath()
const emit = defineEmits(['loggedIn'])
const auth = useAuthStore()

const password = ref('')
const emailAddress = ref('')

const loginErrors = ref([])

watch([emailAddress, password], () => {
  loginErrors.value = []
})

// methods
const doLogin = async () => {
  try {
    await auth.login(emailAddress.value, password.value)
    emit('loggedIn')
  } catch (error) {
    loginErrors.value = error.data.data
  }
}

const state = reactive({
  emailErrorMessage: '',
  passwordErrorMessage: '',
})

const handleLogin = () => {
  if (!emailAddress.value || emailAddress.value === '') {
    state.emailErrorMessage = t('comp_right_panel_for_auth_login.required')
    return
  }
  state.emailErrorMessage = ''
  if (!password.value || password.value === '') {
    state.passwordErrorMessage = t('comp_right_panel_for_auth_login.required')
    return
  }
  state.passwordErrorMessage = ''
  submitForm()
}

const submitForm = async () => {
  doLogin()
}

const isKDollarEnabled = useRuntimeConfig().isKDollarEnabled
</script>

<style lang="scss">
@import 'assets/css/components/nu-right-panel-for-auth.css';
</style>

<template>
  <div class="nu-right-panel-body">
    <div class="nu-right-panel-header">
      <h2>{{ $t('comp_right_panel_for_auth_login.login') }}</h2>
      <div class="nu-right-panel-links">
        <span @click="$emit('linkClick', 'register')">
          {{ $t('comp_right_panel_for_auth_login.create_an_account') }}
        </span>
        {{ $t('comp_right_panel_for_auth_login.or') }}
        <span @click="$emit('linkClick', 'guest')">
          {{ $t('comp_right_panel_for_auth_login.checkout_as_guest') }}
        </span>
        <template v-if="isKDollarEnabled">
          {{ ' ' + $t('comp_right_panel_for_auth_login.or') }}
          <span @click="$emit('linkClick', 'kDollar')"> KDollar </span>
        </template>
      </div>
    </div>
    <div class="nu-right-panel-content">
      <NuInput
        :error-message="state.emailErrorMessage"
        :label="$t('comp_right_panel_for_auth_login.email_address')"
        id="emailAddress"
        class="custom-input"
        type="email"
        v-model="emailAddress"
      />
      <NuPassword
        :errorMessage="state.passwordErrorMessage"
        :label="$t('comp_right_panel_for_auth_login.password')"
        id="password"
        class="custom-input"
        v-model="password"
      />
      <div class="nu-login-forgot-password">
        <NuxtLink :to="localePath({path: `/reset-password`})">{{
          $t('comp_right_panel_for_auth_login.forgot_password')
        }}</NuxtLink>
      </div>

      <div class="authentication-actions">
        <NuButton
          :display="'primary'"
          class="login-btn"
          @click="handleLogin"
        >
          {{ $t('comp_right_panel_for_auth_login.login') }}
        </NuButton>
      </div>

      <div class="login-errors mt-3">
        <div
          class="login-error text-danger"
          :key="index"
          v-for="(error, index) in loginErrors"
        >
          <span>{{ error.code }}</span>
          <span> - </span>
          <span>{{ error.message }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
