<script setup>
const {t} = useI18n()
const smsCode = ref()
const phoneCode = ref()
const state = reactive({
  smsErrorCode: '',
})
const handleRequestCode = () => {
  if (smsCode && smsCode.value && smsCode.value.toString().length > 15) {
    state.smsErrorCode = t('comp_nu_link_dollar.invalid_phone_no')
    return
  }
  state.smsErrorCode = ''
  submitForm()
}

const confirm = () => {}
const skip = () => {}

const submitForm = async () => {
  const {data: responseData} = await useFetch(nuApiUrl('request-code', false), {
    method: 'post',
    body: {},
  })

  console.log(responseData.value)
}
</script>

<style lang="scss" scoped>
@import 'assets/css/components/right-panel-for-auth/link-k-dollar.css';
</style>

<template>
  <div class="nu-right-panel-body">
    <div class="nu-right-panel-header">
      <h2>{{ $t('comp_nu_link_dollar.heading') }}</h2>
    </div>
    <div class="nu-right-panel-content">
      <p>{{ $t('comp_nu_link_dollar.sms_code_sub_title') }}</p>
      <div class="nu-login-phone">
        <label class="nu-sms-code-label"> {{ $t('comp_nu_link_dollar.phone_number') }}</label>
        <NuPhoneCode @onSelect="phoneCode = $event" />
        <NuInput
          :error-message="state.phoneNumberErrorCode"
          id="phoneNumber"
          class="custom-input"
          type="number"
          v-model="phoneNumber"
        />
      </div>

      <div class="nu-login-sms">
        <NuInput
          :error-message="state.smsErrorCode"
          :label="$t('comp_nu_link_dollar.sms_code')"
          id="smsCode"
          class="custom-input"
          type="number"
          v-model="smsCode"
        />
        <NuButton
          :display="'primary'"
          class="request-code-btn"
          @click="handleRequestCode"
        >
          {{ $t('comp_nu_link_dollar.request_code') }}
        </NuButton>
      </div>

      <NuButton
        :display="'primary'"
        class="confirm-btn"
        @click="confirm"
      >
        {{ $t('comp_nu_link_dollar.confirm') }}
      </NuButton>
      <NuButton
        :display="'secondary'"
        class="skip-btn"
        @click="skip"
      >
        {{ $t('comp_nu_link_dollar.skip') }}
      </NuButton>
    </div>
  </div>
</template>
